import React, { useEffect } from "react";
import { Router } from "@reach/router";
import {
  HomePage,
  ContactUsPage,
  DynamicPage,
  AboutUsPage,
  homeTemplatesVersionVariantTypes,
  aboutUsTemplatesVersionVariantsTypes,
  contactUsTemplatesVersionVariantsTypes,
  dynamicTemplatesVersionVariantsTypes,
  colorsVariantsTypes,
  fontsFamiliesTypes,
  colorsTypes,
} from "@hellobuild/blossom-websites-core-package";

import templateVariantSettings from "config";

function IndexPage() {
  //  Propiedades comunes entre los Pages/Paginas
  const commonProps = {
    // Configuracion inicial de la plantilla
    pageTitleProps: {
      TitleColor: "#0C1C4C",
      SubtitleColor: "#0C1C4C",
    },
    headerMobileNoFilterLogo: true,
    headerMobileNoFilterMenuIcon: true,
    config: templateVariantSettings,
    // Habilitar o Desabilitar background de tipo sombra al abrir el menu de navegacion
    backgroundScreenHeader: true,
    //  Configuracion personalizada del Navigation Bar (Menu de Navegacion)
    navigationMenuOptions: {
      // Cambiar la altura del sub-menu de navegacion
      dropdownSeparationFromBottom: 23,
      fixed: true,
      dropdownHoverBackground: colorsVariantsTypes.TRANSPARENT,
      backgroundScreenActive: false,
    },
    // Personalizar Footer
    footerOptions: {},
  };

  const footerFirstLogoOnClick = () => {
    if (!window || !window.document || !document) {
      setTimeout(footerFirstLogoOnClick, 350);
      return;
    }

    findTextAndStyle(document.querySelectorAll(".FOOTER *"), "Routing #:", n => {
      // eslint-disable-next-line no-param-reassign
      n.style.cssText = "font-size: 22px";
    });
  };

  useEffect(() => {
    setTimeout(footerFirstLogoOnClick, 1000);
  }, []);

  return (
    <Router>
      <HomePage
        path="/"
        variant={homeTemplatesVersionVariantTypes.HOME_TEMPLATE_V5}
        {...commonProps}
        // Forzar el uso de un Template
        // forceCustomVariant
        // variant={homeTemplatesVersionVariantTypes.HOME_TEMPLATE_V3}
        // Personalizar Hero
        heroProps={{
          //  Title Font Size
          titleSize: colorsVariantsTypes.WHITE,
          //  Title Font Family
          titleFontFamily: fontsFamiliesTypes.SECONDARY,
          //  Description Font Size
          subTitleSize: colorsVariantsTypes.WHITE,
          //  Description Font Family
          subTitleFontFamily: fontsFamiliesTypes.SECONDARY,
          //  Custom Gradient
          gradient: `linear-gradient(106.01deg, rgba(0, 0, 0, 0.5) 44.02%, rgba(0, 0, 0, 0.085) 74.63%, rgba(0, 0, 0, 0) 100%)`,
          //  Custom Button
          buttonStylesProps: {
            grayscale: true,
            withoutBorderRadius: true,
            grayscaleAndOFilledTextColor: colorsTypes.PRIMARY_DARK,
            margin: "0 auto",
            height: "auto",
            maxWidth: 640,
          },
        }}
        // Personalizar Lista de Productos e items
        productsProps={{}}
        // Personalizar Feature
        featureProps={{}}
        // Personalizar Feature Full
        featureFullProps={{}}
        // Personalizar Callout
        calloutProps={{}}
        // Personalizar Callout Mobile
        calloutMobileProps={{}}
        // Personalizar Callout Compressed
        calloutCompressedProps={{}}
      />

      <AboutUsPage
        path="/about-us"
        variant={aboutUsTemplatesVersionVariantsTypes.ABOUT_US_TEMPLATE_V2}
        {...commonProps}
        // Personalizar Page Header
        pageHeaderProps={{}}
        // Personalizar Body Text
        bodyTextProps={{}}
        // Personalizar Callout Text
        calloutTextProps={{}}
        // Personalizar Callout Button
        calloutButtonProps={{}}
      />

      <ContactUsPage
        path="/contact-us"
        variant={contactUsTemplatesVersionVariantsTypes.CONTACT_US_TEMPLATE_V2}
        {...commonProps}
        // Personalizar Submit Button
        submitButton={{}}
        // Personalizar Header
        pageHeaderProps={{}}
        // Personalizar Page Title
        pageTitleProps={{}}
        // Personalizar Contact
        contactProps={{}}
        // Personalizar Branch
        branchProps={{}}
        // Personalizar  Callout
        calloutProps={{}}
        // Personalizar  Callout Mobile With Gradient
        calloutMobileWithGradientPros={{}}
      />

      <DynamicPage
        path="/:id"
        variant={dynamicTemplatesVersionVariantsTypes.DYNAMIC_TEMPLATE_V2}
        {...commonProps}
        // Personalizar Custom Page
        customPageProps={{}}
        // Personalizar Hero
        heroProps={{}}
        // Personalizar Lista de Productos
        productsProps={{}}
        // Personalizar Feature Full
        featureFullProps={{}}
        // Personalizar Feature
        featureProps={{}}
        // Personalizar Callout
        calloutProps={{}}
        // Personalizar Callout Mobile
        calloutMobileProps={{}}
        // Personalizar Callout Compressed
        calloutCompressedProps={{}}
      />
    </Router>
  );
}

// ==== functions =====
/**
 * Makes a node's p element get transformed
 * @param {*} node
 * @returns node
 */
function findTextAndStyle(
  node,
  keyword = "Routing #:",
  actionOnNode = n => {
    // eslint-disable-next-line no-param-reassign
    n.style.cssText = "font-size: 40px;";
  },
) {
  if (node.innerHTML === keyword) {
    node.parentElement.querySelectorAll("p").forEach(n => {
      actionOnNode(n);
    });
    return node;
  }

  if (node.length) {
    return node.forEach(n => findTextAndStyle(n, keyword, actionOnNode));
  }

  return node;
}

export default IndexPage;
